import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout title="Small Saucepan" location={location}>
      <SEO title="About Us" />
      <p>
        Small Saucepan is a <i>tiny</i> website and app development consultancy.
        We have a breadth of experience that we can apply to solving your tricky
        IT problem.
      </p>
      <h2>What's in a name?</h2>
      <p>
        After helping a friend with a particularly stubborn technical issue they
        remarked, You're as handy as a small saucepan!
      </p>
      <p>The analogy works, so the name stuck.</p>
      <p>
        If you are facing an website or app issue that's out of the ordinary,
        please <a href="/contact">get in touch</a>.
      </p>
      <h2>Mobile Applications</h2>
      <p>
        <Link style={{ boxShadow: `none` }} to="/apps/pumpmate">
          Pump Mate
        </Link>
      </p>
      <p>
        <Link style={{ boxShadow: `none` }} to="/apps/winching">
          Winching
        </Link>
      </p>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
